<template>
  <div
    class="rn_my_slide"
    id="main_left"
    :class="{ goods_rn_my_slide: isGoods && !isSeller }"
  >
    <div class="left_slide" v-if="isMoreMenu">
      <router-link to="">&lt;</router-link>
    </div>
    <div class="slide_cate" :style="isMoreMenu ? '' : { width: '100%' }">
      <div class="slide_box">
        <div class="swiper_box" v-if="isMoreMenu">
          <swiper
            class="swiper"
            :options="swiperOption"
            ref="swiperWrap"
            v-if="mainMenuList.length > 0"
          >
            <template v-for="item in mainMenuList">
              <swiper-slide
                :key="item.seq"
                :data-menu="item.seq"
                class="market"
              >
                {{ item.title }}
              </swiper-slide>
            </template>
          </swiper>
        </div>
        <div class="box swiper" v-else>
          <div
            v-for="(item, idx) in mainMenuList"
            :key="item.seq"
            @click="checkMenu(item.seq, $event)"
            :class="{
              on: parseInt(idx) === parseInt(id),
              goods_back: isGoods && !isSeller,
            }"
            :style="
              isGoods && allowMenu.includes(parseInt(item.seq))
                ? ''
                : 'display:none'
            "
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div class="right_slide" v-if="isMoreMenu" id="main_right">
      <router-link to="">&gt;</router-link>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
let vm = null;
export default {
  props: {
    isMoreMenu: {
      type: Boolean,
      default: false,
    },
    mainMenuList: {
      type: Array,
      default: () => [],
    },
    isGoods: {
      type: Boolean,
      Default: false,
    },
    isSeller: {
      type: Boolean,
      Default: false,
    },
    allowMenu: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      swiperOption: {
        enabled: this.$route.meta.main,
        allowSlideNext: this.$route.meta.main,
        allowSlidePrev: this.$route.meta.main,
        allowTouchMove: this.$route.meta.main,
        initialSlide: this.id,
        slidesPerView: 5,
        loop: true,
        pagination: false,
        navigation: {
          nextEl: "#main_left",
          prevEl: "#main_right",
        },
        centeredSlides: true, //처음 슬라이드를 가운데 배치
        slideToClickedSlide: true, //슬라이드 위치로 이동
        on: {
          slideChangeTransitionEnd: function (e) {
            [...document.querySelectorAll(".market.swiper-slide")].map((sw) =>
              sw.classList.remove("on")
            );
            // const targetParent = e.target.closest("div");
            // targetParent.classList.add("on");
            document
              .querySelector(".market.swiper-slide-active")
              .classList.add("on");
            const target = document.querySelector(
              ".market.swiper-slide-active.on"
            );
            const seq = target.dataset.menu;
            vm.checkMenu(seq);
          },
        },
      },
    };
  },
  created() {
    vm = this;
  },
  methods: {
    checkMenu(num) {
      this.$emit("getMenu", parseInt(num));
    },
  },
  computed: {
    page() {
      const path = this.$route.path.split("/")[1];
      if (path === "market_category_list") {
        return false;
      }
      return true;
    },
  },
  components: { Swiper, SwiperSlide },
};
</script>

<style lang="scss" scoped>
.goods_back {
  background: #1a6bbd !important;
  color: #fff !important;
}
.goods_rn_my_slide {
  background: #6fa7e0 !important;
  border-bottom: 3px solid #1a6bbd !important;
}
</style>
