<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_3">
      램 가게
      <div class="search">
        <div class="input_box">
          <input
            type="text"
            name=""
            v-model="searchText"
            @keydown.enter="goSearch"
            placeholder="상품을 검색해주세요."
          />
        </div>
        <router-link to="" @click.native="goSearch"
          ><img src="@/assets/images/sub/alarm_search_bt.png" alt=""
        /></router-link>
      </div>
    </div>
    <!---->
    <!-- <div class="rn_ms_empty_30"></div> -->
    <!---->
    <MainMenu
      :mainMenuList="mainMenuList"
      @getMenu="getMenu"
      :isGoods="true"
      :allowMenu="allowMenu"
    />
    <!---->
    <div class="rn_ms_section goods_border">
      <div class="rn_ms_box_10">
        <div class="top" @click="moveMiniShop">
          <img
            :src="
              providerInfo.banner_visual
                ? providerInfo.banner_visual
                : '/data/skin/interior_comfortable_gls/images/common/mns_banner.gif'
            "
            alt=""
          />
        </div>
        <div class="sect_box">
          <div class="title">
            판매자 정보<router-link to="" @click.native="goBack"
              ><img src="@/assets/images/sub/back_ico.png" alt=""
            /></router-link>
          </div>
          <div class="shop_seller">
            <h4>{{ providerInfo.provider_name }}</h4>
            <ul>
              <li>
                <div class="left">{{ providerInfo.info_phone }}</div>
                <div class="right">
                  <a :href="`tel:${providerInfo.info_phone}`" class="gray"
                    >판매자 전화 문의</a
                  >
                </div>
              </li>
              <li>
                <div class="left">잘못된 전화번호인 경우 신고해 주세요!</div>
                <div class="right">
                  <router-link to="/cs/qna" class="white">신고하기</router-link>
                </div>
              </li>
            </ul>
          </div>
          <div class="shop_grade">
            <p>{{ providerInfo.provider_name }}의 신뢰도 지수는?</p>
            <h5>{{ rammFloat(providerInfo.trust_score) }}</h5>
          </div>
          <div class="shop_info">
            <h4>사업자 정보</h4>
            <table summary="">
              <colgroup>
                <col width="115px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <td>상호명</td>
                  <td>{{ providerInfo.provider_name }}</td>
                </tr>
                <tr>
                  <td>대표자</td>
                  <td>{{ providerInfo.info_ceo }}</td>
                </tr>
                <tr>
                  <td>통신판매업신고번호</td>
                  <td>{{ providerInfo.info_selling_license }}</td>
                </tr>
                <tr>
                  <td>사업자등록번호</td>
                  <td>{{ providerInfo.info_num }}</td>
                </tr>
                <tr>
                  <td>사업장소재지</td>
                  <td>
                    {{ providerInfo.info_address1_street
                    }}{{ providerInfo.info_address2 }}({{
                      providerInfo.info_zipcode
                    }})
                  </td>
                </tr>
                <tr>
                  <td>이메일</td>
                  <td>{{ providerInfo.info_email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="rn_info">
          주식회사 리뷰앤메이크머니는 통신판매 당사자가 아닌
          통신판매중개자입니다. 상품, 상품정보, 거래에 관한 의무와 책임은
          통신판매 당사자인 판매자에게 있습니다.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainMenu from "@/components/shop/menu/MainMenu";
export default {
  data() {
    return {
      allowMenu: [1], //현재 램 장터메 활성화된 메뉴 번호
      providerId: this.$route.params.provider_id || null,
      categoryId: this.$route.query.category_id || null,
      goodsSeq: this.$route.query.goods_seq || null,
      searchText: "",
    };
  },
  created() {
    //초기화
    this.$store.dispatch("shop/dataClear");
    this.getMainMenu();
  },
  computed: {
    ...mapState("review", ["mainMenuList"]),
    ...mapState("shop", ["result", "msg", "providerInfo"]),
  },
  methods: {
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    moveMiniShop() {
      this.$router.push(`/mini_shop/${this.providerId}`);
    },
    goSearch() {
      if (this.searchText.replace(/\s/g, "").length <= 0) {
        this.$toast.default("검색어를 입력하세요.");
        return false;
      }
      this.$router.push(`/search/goods_search?search=${this.searchText}`);
    },
    goBack() {
      const url = `/market_goods_detail/${this.categoryId}/${this.goodsSeq}`;
      this.$router.push(url);
    },
    async getMainMenu() {
      await this.$store.dispatch("review/getMainMenu");
      await this.$store.dispatch("shop/getSellerInfo", {
        provider_seq: this.providerId,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
    },
    getMenu(menu) {},
  },
  components: {
    MainMenu,
  },
};
</script>

<style></style>
